function PhotoLayout({images, altText}) {
    return (
        <div className='photo-layout-container'>
            <ul className='photo-list'>
                {images.map((item, index) => {
                    return <li className='photo-list-item' key={item}><img src={item} className='photo-item' alt={altText[index]} /></li>
                })}
            </ul>
        </div>
    )
}

export default PhotoLayout