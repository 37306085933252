import React, { useState, useEffect } from 'react'
import db, { storage } from '../firebase.config'
import { collection, getDocs } from "firebase/firestore"
import { getDownloadURL, ref } from "firebase/storage"
import { useParams, Redirect } from "react-router-dom"
import PhotoLayout from './photolayout'

export default function Article() {
    const [blogs, setBlogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    let params = useParams();

    const navigateExternalNewTab = (url) => {
        console.log("hi");
        window.open(url, '_blank');
    };

    useEffect(() => {
        (async function () {

            const querySnapshot = await getDocs(collection(db, "blogs"));

            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
                coverImgUrl: [],
                portraitImgUrl: [],
                landscapeImgUrl: [],
                imagesFullUrl: new Map()
            })).filter(post => post.id === params.blogId);

            // Create a reference with an initial file path and name
            if (data.length > 0) {
                const map = new Map(Object.entries(data[0].images));
                map.forEach((value, key) => {
                        const pathReference = ref(storage, data[0].imagesDir + value);
                        // Get the download URL
                        getDownloadURL(pathReference).then((url) => {
                            data[0].imagesFullUrl.set(key, url);
                            if (data[0].imagesFullUrl.size === map.size) {
                                setBlogs(data);
                                setIsLoading(false);
                            }
                        }).catch((error) => {
                            // A full list of error codes is available at
                            // https://firebase.google.com/docs/storage/web/handle-errors
                            switch (error.code) {
                                case 'storage/object-not-found':
                                    // File doesn't exist
                                    console.log("case 1");
                                    break;
                                case 'storage/unauthorized':
                                    // User doesn't have permission to access the object
                                    console.log("case 2");
                                    break;
                                case 'storage/canceled':
                                    // User canceled the upload
                                    console.log("case 3");
                                    break;
                                // ...
                                case 'storage/unknown':
                                    // Unknown error occurred, inspect the server response
                                    console.log("case 4");
                                    break;
                                default:
                                    console.log("case 5");
                                    break;
                            }
                        })
                })
            } else {
                setBlogs(null);
                setIsLoading(false);
            }
        })();
    }, [params.blogId]);

    return (
        <section className="feature-container"> {
            isLoading ? <div></div> :
            blogs ? blogs.map((blog, index) => {
                let paragraphs = blog.body.split("\\n");
                return (
                    <section key={blog.id} className="post">
                        <img src={blog.imagesFullUrl.get("0")} alt={blog.altText[0]} />
                        <h1>{blog.title}</h1>
                        <p>{paragraphs[0]}</p>
                        <p>{paragraphs[1]}</p>
                        <p>{paragraphs[2]}</p>
                        <img src={blog.imagesFullUrl.get("2")} alt={blog.altText[2]} />
                        <p>{paragraphs[3]}</p>
                        <p>{paragraphs[4]}</p>
                        {/* <p dangerouslySetInnerHTML={{__html: paragraphs[5]}}></p> */}
                        <p>So what have I done to combat anxiety, stress, and depression? I don’t think I did anything special, I started listening to the <button onClick={() => navigateExternalNewTab("https://www.wakingup.com")}>Waking Up</button> app by Sam Harris and started practicing mindfulness meditation. That was a contributing factor that got me through the PORTER. I saw a social worker twice and overall it was a good experience. Releasing my pent-up feelings felt liberating, helping me process and move on. I learned it was important to recognize and acknowledge my feelings, and then let them dissipate. Emotions are fleeting after all. It was difficult to go through this, but it ultimately strengthened me. With each challenge I’ve become more stoic, resilient, and a better version of myself. I am all the more ready for my next whatever-life crisis, and I always remember that it's okay to be not okay.</p>
                        <PhotoLayout images={Array.from(blog.imagesFullUrl.values())} altText={blog.altText} />
                    </section>
                )
            }) : <Redirect to="/errorpage" />
        }
        </section>
    )
}