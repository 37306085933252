import React, { useState, useEffect } from 'react'
import db,  { storage } from '../firebase.config'
import { collection, getDocs } from "firebase/firestore"
import { getDownloadURL, ref } from "firebase/storage"
import { Link } from 'react-router-dom'

function Posts() {
const [blogs, setBlogs] = useState([]);

const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric"}
    return new Date(dateString).toLocaleDateString(undefined, options)
    }

  useEffect(() => {
    (async function() {
        const querySnapshot = await getDocs(collection(db, "blogs"));
        const data = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          }));

            // Create a reference with an initial file path and name
            const pathReference = ref(storage, data[0].url);
            // Get the download URL

            getDownloadURL(pathReference).then((url) => {
                // Insert url into an <img> tag to "download"
                data[0].url = url;
                setBlogs(data);
            }).catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/object-not-found':
                    // File doesn't exist
                    break;
                    case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;
                    case 'storage/canceled':
                    // User canceled the upload
                    break;
                    // ...
                    case 'storage/unknown':
                    // Unknown error occurred, inspect the server response
                    break;
                    default:
                    
                    break;
                }
            });
    })();
  }, []);

    return (
        <section className="post-container">
            <div className="page-header">
                <h1>Post & stuff</h1>
            </div>
            <hr className="solid"></hr>
            <section className="posts-container">
            {
                 blogs && blogs.map(blog => {
                    return (
                        <section key={blog.id} className="post-item" style={{ backgroundImage: `url(${ blog.url })` }}>
                            <header className="information">
                                <h1 className="title">
                                    <Link to={{ pathname: `/post/${ blog.id }` }}>{blog.title}</Link>
                                </h1>
                                <time className="article-date" dateTime={blog.timestamp.toLocaleDateString}>
                                    {formatDate(blog.timestamp.toDate())}
                                </time>
                            </header>
                        </section>
                    )
                })
            }
            </section>
        </section>
    )
}

export default Posts