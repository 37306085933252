import React, { useState, useEffect } from 'react'
import db,  { storage } from '../firebase.config'
import { collection, getDocs } from "firebase/firestore"
import { getDownloadURL, ref } from "firebase/storage"

function About() {

    const [images, setImages] = useState([]);

    useEffect(() => {
        (async function() {
            const querySnapshot = await getDocs(collection(db, "images"));
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
              }));

              let data2 = data.filter(post => post.id === "RlVrTDtUS08SbtP7R3BT");
            

            // Create a reference with an initial file path and name
            const pathReference = ref(storage, data2[0].url);
            // Get the download URL

            getDownloadURL(pathReference).then((url) => {
                // Insert url into an <img> tag to "download"
                data2[0].url = url;
                setImages(data2);
            }).catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/object-not-found':
                    // File doesn't exist
                    break;
                    case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;
                    case 'storage/canceled':
                    // User canceled the upload
                    break;
                    // ...
                    case 'storage/unknown':
                    // Unknown error occurred, inspect the server response
                    break;
                    default:
                    
                    break;
                }
            });
        })();
    }, []);

    return (
        <section className="feature-container">
            {
                images && images.map(image => {
                    return (
                        <section key={image.id} className={`background about-me-container`} style={{ backgroundImage: `url(${ image.url })` }}>
                            <aside className="about-me">
                                <header>
                                    <h1>About Me</h1>
                                    <h2>Hi, my name is Noel and I sometimes do neat things</h2>
                                </header>
                                <p>
                                    I graduated from San Jose State University in Software Engineering.
                                    Since then I joined the Navy, was stationed in paradise (or hell), 
                                    Diego Garcia. Got sent to Spain, loaded up on tapas and sangria, 
                                    and spent time defending the Mediterranean Sea from evil. After about six and half
                                    years of service and accumulated stress I decided to give civilian life another shot.
                                    <br />
                                    <br />
                                    My first job landed me in Omaha, Nebraska off all places working as
                                    a Web Developer/DevOps Engineer. The Mid-West is ok, but I don't think I could live
                                    in middle America permanently.
                                    I am currently working with a defense contractor in Software Engineering 
                                    role back in my home state of California in the San Francisco Bay Area.
                                    <br />
                                    <br />
                                    I occasionally venture off with my camera and if I get lucky, I 
                                    get some really great amateur shots (this 
                                    shot was taken by my good friend Edward Chew). So I promise to 
                                    present you with the finest amateur photography and some of
                                    my interests.
                                </p>
                            </aside>
                        </section>
                    )
                })
            }
        </section>
    )
}

export default About